import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import authRoutes from './auth';
import businessRoutes from './business';
import userRoutes from './users';
import apiKeyRoutes from './apiKeys';
import deviceRoutes from './devices';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...businessRoutes,
  ...userRoutes,
  ...apiKeyRoutes,
  ...deviceRoutes,
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business/pricing',
    name: 'pricing',
    component: () => import('@/views/business/Pricing.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const requiresSuper = to.matched.some(record => record.meta.requiresSuper);
  const user = await firebase.getCurrentUser();

  if (requiresAuth) {
    if (!user) {
      next('login');
    } else {
      next();
    }
  } else if (requiresAdmin) {
    if (!user) {
      next('login');
    }
    if (store.state.auth.admin) {
      next();
    } else {
      next('login');
    }
  } else if (requiresSuper) {
    if (!user) {
      next('login');
    }
    if (store.state.auth.accountType === 'super' && store.state.auth.admin) {
      next();
    } else {
      next('login');
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router;
