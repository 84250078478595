const businessRoutes = [{
  path: '/businesses',
  name: 'businesses',
  component: () => import('../views/admin/businesses/Businesses'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Businesses',
    breadcrumb: [
      {
        text: 'Businesses',
        active: true,
      },
    ],
  },
}, {
  path: '/business/:id',
  name: 'businessEdit',
  component: () => import('../views/admin/businesses/EditBusiness'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Edit Business',
    breadcrumb: [
      {
        text: 'Businesses',
        active: false,
        to: '/businesses',
      },
      {
        text: 'Edit',
        active: true,
      },
    ],
  },
}, {
  path: '/business',
  name: 'businessAdd',
  component: () => import('../views/admin/businesses/AddBusiness'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Add Business',
    breadcrumb: [
      {
        text: 'Businesses',
        active: false,
        to: '/businesses',
      },
      {
        text: 'Add',
        active: true,
      },
    ],
  },
}];

export default businessRoutes;
