import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

let firebaseConfig = {};

if (process.env.VUE_APP_ENV !== 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyC7qVsNnVG_pR5yZgDfUGPm-ua_UjHUwgg',
    authDomain: 'smartcities-m2f-staging.firebaseapp.com',
    databaseURL: 'https://smartcities-m2f-staging-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'smartcities-m2f-staging',
    storageBucket: 'smartcities-m2f-staging.appspot.com',
    messagingSenderId: '1000823101268',
    appId: '1:1000823101268:web:bf0a47552db289eb171ac1',
    measurementId: 'G-E6X0C3G804',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBYAmm3yVOW2B903XnM0WCej4pj2gO6Nfw',
    authDomain: 'smartcities-m2f-prod.firebaseapp.com',
    databaseURL: 'https://smartcities-m2f-prod-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'smartcities-m2f-prod',
    storageBucket: 'smartcities-m2f-prod.appspot.com',
    messagingSenderId: '88346680106',
    appId: '1:88346680106:web:8ea2b1bf95113277425c53',
    measurementId: 'G-Y6C2VXMSVJ',
  }
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// eslint-disable-next-line
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};
