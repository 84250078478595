import axios from 'axios';
import * as api from '../../helpers/api';

export default {
  state: {
    businesses: [],
  },
  mutations: {
    setBusinesses(state, val) {
      state.businesses = val;
    },
  },
  actions: {
    async getBusinesses() {
      const data = await axios({
        method: 'get',
        url: api.businesses,
        // headers: {
        //   Authorization: `Bearer ${rootState.auth.authToken}`,
        // },
      });
      this.commit('setBusinesses', data.data.data);
    },
  },
}
