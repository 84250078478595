import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/analytics';

import './store/firebase';

import axios from 'axios';

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// Add a request interceptor
axios.interceptors.request.use(config => {
  // Do something before request is sent
  // eslint-disable-next-line
  config.headers.Authorization = `Bearer ${store.state.auth.authToken || ''}`;
  return config;
}, error => {
  Vue.$toast.error(error.message);
  return Promise.reject(error)
});

// Add a response interceptor
axios.interceptors.response.use(response => response, error => {
  if (!error.response) {
    Vue.$toast.error('Network Error');
    return Promise.reject(error);
  }

  if (error.response.status === 401 || error.response.status === 403) {
    Vue.$toast.error('Unauthorised for that request');
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
