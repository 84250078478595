const deviceRoutes = [{
  path: '/devices',
  name: 'devices',
  component: () => import('../views/devices/Devices'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Devices',
    breadcrumb: [
      {
        text: 'Devices',
        active: true,
      },
    ],
  },
}, {
  path: '/device/:id',
  name: 'deviceEdit',
  component: () => import('../views/devices/EditDevice'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Edit Device',
    breadcrumb: [
      {
        text: 'Devices',
        active: false,
        to: '/devices',
      },
      {
        text: 'Edit',
        active: true,
      },
    ],
  },
}, {
  path: '/device',
  name: 'deviceAdd',
  component: () => import('../views/devices/AddDevice'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Add Device',
    breadcrumb: [
      {
        text: 'Devices',
        active: false,
        to: '/devices',
      },
      {
        text: 'Add',
        active: true,
      },
    ],
  },
}];

export default deviceRoutes;
