import axios from 'axios';
import * as api from '../../helpers/api';

export default {
  state: {
    nodes: [],
    dataCount: 0,
  },
  mutations: {
    setNodes(state, val) {
      state.nodes = val;
    },
    setDataCount(state, val) {
      state.dataCount = val;
    },
  },
  actions: {
    async getNodes() {
      const data = await axios({
        method: 'get',
        url: api.nodes,
      });
      this.commit('setNodes', data.data.data);
    },
    async getDataCount() {
      const data = await axios({
        method: 'get',
        url: api.dataPoints,
      });
      this.commit('setDataCount', data.data.data);
    },
  },
}
