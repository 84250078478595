import axios from 'axios';
import * as api from '../../helpers/api';

export default {
  state: {
    users: [],
    apiKeys: [],
  },
  getters: {

  },
  mutations: {
    setUsers(state, val) {
      state.users = val;
    },
    setApiKeys(state, val) {
      state.apiKeys = val;
    },
  },
  actions: {
    async getUsers() {
      const data = await axios({
        method: 'get',
        url: api.users,
      });
      this.commit('setUsers', data.data.data);
    },
    async getApiKeys() {
      const data = await axios({
        method: 'get',
        url: api.apiKeys,
      });
      this.commit('setApiKeys', data.data.data);
    },
  },
}
