const node = `${process.env.VUE_APP_BASE_URL}/node`;
const nodes = `${process.env.VUE_APP_BASE_URL}/nodes`;
const users = `${process.env.VUE_APP_BASE_URL}/users`;
const user = `${process.env.VUE_APP_BASE_URL}/user`;
const dataPoints = `${process.env.VUE_APP_BASE_URL}/dataPoints`;
const businesses = `${process.env.VUE_APP_BASE_URL}/businesses`;
const business = `${process.env.VUE_APP_BASE_URL}/business`;
const me = `${process.env.VUE_APP_BASE_URL}/me`;
const apiKeys = `${process.env.VUE_APP_BASE_URL}/apiKeys`;
const apiKey = `${process.env.VUE_APP_BASE_URL}/apiKey`;

export {
  node,
  nodes,
  users,
  businesses,
  business,
  dataPoints,
  me,
  user,
  apiKeys,
  apiKey,
}
