const authRoutes = [{
  // path: '/register',
  // name: 'register',
  // component: () => import('../views/auth/Register'),
  // meta: {
  //   layout: 'full',
  // },
// },
// {
  path: '/login',
  name: 'login',
  component: () => import('../views/auth/Login'),
  meta: {
    layout: 'full',
  },
},
{
  path: '/forgotPassword',
  name: 'forgotPassword',
  // component: () => import('../views/auth/ForgotPassword'),
  meta: {
    layout: 'full',
  },
}];

export default authRoutes;
