import axios from 'axios';
import * as api from '../../helpers/api';

export default {
  state: {
    admin: false,
    uid: '',
    authToken: '',
    name: '',
    accountType: '',
    loggedIn: false,
    businessId: '',
    me: {},
  },
  getters: {

  },
  mutations: {
    setAdmin(state, val) {
      state.admin = val;
    },
    setName(state, val) {
      state.name = val;
    },
    setAccountType(state, val) {
      state.accountType = val;
    },
    setLogin(state, val) {
      state.loggedIn = val;
    },
    setUID(state, val) {
      state.uid = val;
    },
    setAuthToken(state, val) {
      state.authToken = val;
    },
    setBusinessId(state, val) {
      state.businessId = val;
    },
    setMe(state, val) {
      state.me = val;
    },
    logout(state) {
      state.loggedIn = false;
      state.admin = false;
      state.name = '';
      state.uid = '';
      state.authToken = '';
      state.accountType = '';
      state.businessId = '';
      state.me = {};
    },
  },
  actions: {
    async getMe() {
      const data = await axios({
        method: 'get',
        url: api.me,
      });
      this.commit('setMe', data.data.data);
    },
  },
}
