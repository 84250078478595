const userRoutes = [{
  path: '/users',
  name: 'users',
  component: () => import('../views/users/Users'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Users',
    breadcrumb: [
      {
        text: 'Users',
        active: true,
      },
    ],
  },
}, {
  path: '/user/:id',
  name: 'userEdit',
  component: () => import('../views/users/EditUser'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Edit User',
    breadcrumb: [
      {
        text: 'Users',
        active: false,
        to: '/users',
      },
      {
        text: 'Edit',
        active: true,
      },
    ],
  },
}, {
  path: '/user',
  name: 'userAdd',
  component: () => import('../views/users/AddUser'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Add User',
    breadcrumb: [
      {
        text: 'Users',
        active: false,
        to: '/users',
      },
      {
        text: 'Add',
        active: true,
      },
    ],
  },
}];

export default userRoutes;
