const apiKeyRoutes = [{
  path: '/apikeys',
  name: 'apiKeys',
  component: () => import('../views/apiKeys/ApiKeys'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Api Keys',
    breadcrumb: [
      {
        text: 'Api Keys',
        active: true,
      },
    ],
  },
}, {
  path: '/apikey/:id',
  name: 'apiKeyEdit',
  component: () => import('../views/apiKeys/EditApiKey'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Edit Api Key',
    breadcrumb: [
      {
        text: 'Api Keys',
        active: false,
        to: '/apikeys',
      },
      {
        text: 'Edit',
        active: true,
      },
    ],
  },
}, {
  path: '/apikey',
  name: 'apiKeyAdd',
  component: () => import('../views/apiKeys/AddApiKey'),
  meta: {
    requiresAuth: true,
    pageTitle: 'Add Api Key',
    breadcrumb: [
      {
        text: 'Api Keys',
        active: false,
        to: '/apikeys',
      },
      {
        text: 'Add',
        active: true,
      },
    ],
  },
}];

export default apiKeyRoutes;
